import { createSlice, PayloadAction } from "@reduxjs/toolkit"
import { AuthState } from "../../interfaces/types"

const initialStateAuth: AuthState = {
    accesToken: null
}

const AuthSlice = createSlice({
    name: 'auth',
    initialState: initialStateAuth,
    reducers: {
        setToken: (state, action: PayloadAction<string | null>) => {
            state.accesToken = action.payload
        },
    }
})

export default AuthSlice.reducer