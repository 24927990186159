import { Dispatch } from "@reduxjs/toolkit";
import axios from "../utils/axios";
// import Swal from "sweetalert2";
import {
  getProducts,
  getProductById,
  getProductByName,
  getServicios,
  getServicioById,
  getServicioByName,
  getUsers,
  // getUserById,
  // loginUser,
  setIsLoading,
  setUser,
  setProductsByCategory,
} from "./slices/slice";
import { AxiosError } from "axios";
import { Producto } from "../interfaces/types";
import Swal from "sweetalert2";
const localStorage = window.localStorage;

// ** PRODUCTOS

export const getAllProducts = () => (dispatch: Dispatch) => {
  dispatch(setIsLoading(true));
  try {
    axios
      .get(`/productos`)
      .then((res) => dispatch(getProducts(res.data)))
      .catch((e) => console.log(e));
  } catch (error) {
    return error as AxiosError;
  } finally {
    dispatch(setIsLoading(false));
  }
};

export const getProduct = (id: string) => (dispatch: Dispatch) => {
  try {
    axios
      .get(`/productos/${id}`)
      .then((res) => dispatch(getProductById(res.data)))
      .catch((e) => console.log(e));
  } catch (error) {
    return error as AxiosError;
  }
};

export const setCategoryProductos = (productosCategory: any) => (dispatch: Dispatch) => {
  console.log(productosCategory, 'desde actions amigo mio')
  try {
    console.log(productosCategory)
    dispatch(setProductsByCategory(productosCategory))
  } catch (error) {
    console.log(error)
  }
}

export const getProductName = (name: string) => (dispatch: Dispatch) => {
  axios
    .get(`/productos?name=${name}`)
    .then((res) => dispatch(getProductByName(res.data)))
    .catch((e) => console.log(e));
};

export const createProduct = (payload: Producto) => (dispatch: Dispatch) => {
  console.log(payload);
  axios
    .post('/productos', payload)
    .then((res) => {
      if (res.status === 201) {
        Swal.fire({
          title: "Producto Creado",
          text: "Producto creado exitosamente",
          icon: "success",
          confirmButtonText: "OK",
        });
      }
      return Swal.fire({
        title: "Producto Creado",
        text: res.data.message,
        icon: "error",
        confirmButtonText: "OK",
      });
    })
    .catch((error) => {
      return Promise.reject(error);
    })
    .catch((error) => {
      Swal.fire({
        title: "Producto Creado",
        text: error.message,
        icon: "error",
        confirmButtonText: "OK",
      });
    });
};




// ** LOGGIN AND REGISTER
// --- Sea Google - Facebook o Login manual, trae la información de la variable global usuario
export const fetchUserData = () => (dispatch: Dispatch) => {
  console.log('me ejecuto desde actions')
  try {
    fetch("http://localhost:3001/auth/login/good")
      .then((response) => {
        console.log(response, 'desde el front')
        if (response.status === 200) return response.json();
        throw new Error("authentication has been failed!");
      })
      .then((resObject) => dispatch(setUser(resObject)))
  } catch (error) {
    console.error(error);
  }
};


export const logout = () => (dispatch: Dispatch) => {
  try {
    fetch("http://localhost:3001/auth/logout")
      .then((response) => {
        if (response.status === 200) return response.json();
        throw new Error("Logout has been failed!");
      })
      .then((resObject) => dispatch(setUser(resObject)))
  } catch (error) {
    console.error(error);
  }
};

export const manualRegister = (payload: any) => (dispatch: Dispatch) => {
  try {
    axios
      .post('auth/register', payload)
      .then((response) => {
        console.log(response, 'desde manualRegister')
        if (response.status === 200) return response;
        throw new Error("Logout has been failed!");
      })
      .then((resObject) => dispatch(setUser(resObject.data)))
  } catch (error) {
    console.error(error, 'error del post');
  }
}

export const loginRegister = (payload: any) => (dispatch: Dispatch) => {
  try {
    axios
      .post('auth/login', payload)
      .then((response) => {
        console.log(response, 'desde loginRegister')
        if (response.status === 200) return response;
        throw new Error("Logout has been failed!");
      })
      .then((resObject) => dispatch(setUser(resObject.data)))
  } catch (error) {
    console.error(error, 'error del post');
  }
}

export const getAllServices = () => (dispatch: Dispatch) => {
  axios
    .get(`/servicios`)
    .then((res) => dispatch(getServicios(res.data)))
    .catch((e) => console.log(e));
};

export const getService = (id: string) => (dispatch: Dispatch) => {
  axios
    .get(`/servicios/${id}`)
    .then((res) => dispatch(getServicioById(res.data)))
    .catch((e) => console.log(e));
};

export const getServiceName = (name: string) => (dispatch: Dispatch) => {
  axios
    .get(`/servicios?name=${name}`)
    .then((res) => dispatch(getServicioByName(res.data)))
    .catch((e) => console.log(e));
};

export const getAllUsers = () => (dispatch: Dispatch) => {
  dispatch(setIsLoading(true));
  try {
    axios
      .get(`/usuarios`)
      .then((res) => dispatch(getUsers(res.data)))
      .catch((e) => console.log(e));
  } catch (error) {
    return error as AxiosError;
  } finally {
    dispatch(setIsLoading(false));
  }
};
