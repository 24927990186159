import { Modal, Card, Button } from 'react-bootstrap';
import { Producto } from '../../interfaces/types';

interface ProductoModalProps {
  producto: Producto | undefined;
  show: boolean;
  onHide: () => void;
}

export const ProductoModal = (props: ProductoModalProps) => {
  const { producto, show, onHide } = props;

  return (
    <Modal show={show} onHide={onHide}>
      <Modal.Header closeButton>
        <Modal.Title>{producto?.name}</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Card >
          <Card.Img variant="top" className='tex-center' src={producto?.photo} style={{ height: '14rem'}} />
          <Card.Body>
            <Card.Text>Marca {producto?.marca}</Card.Text>
          </Card.Body>
          <Card.Body>
            <Card.Text>Precio {producto?.price}</Card.Text>
          </Card.Body>
          <Card.Body>
            <Card.Text>{producto?.description}</Card.Text>
          </Card.Body>
        </Card>
      </Modal.Body>
      <Modal.Footer>
        <Button variant="secondary" onClick={onHide}>
          Close
        </Button>
      </Modal.Footer>
    </Modal>
  );
}