import { Fort } from "@mui/icons-material";
import React, { useState, useEffect } from "react";
import { Modal, Card, Button } from "react-bootstrap";
import { Producto } from "../../interfaces/types";

interface CarritoProps {
  carrito: Producto[] | undefined;
  show: boolean;
  onHide: () => void;
}

export const Carrito = (props: CarritoProps) => {

  const [ carritoFinal, setCarritoFinal] = useState<Producto[]>([])
  const { carrito, show, onHide } = props;

  useEffect(() => {
    if (carrito !== undefined) {
      setCarritoFinal(carrito)
    }
  }, [setCarritoFinal])
 
  console.log(carritoFinal, 'carrito final');

  const onIncrement = (item: Producto) => {
    console.log('hola')
    if (item.cantidad !== undefined) {
      setCarritoFinal(
        carritoFinal.map((producto) => {
          if (producto._id === item._id && producto.cantidad) {
            return { ...producto, cantidad: producto.cantidad + 1 };
          } else {
            return producto;
          }
        })
      );
    }
  }

  const productosFiltrados = carritoFinal.filter((producto, index) => {
    return index === carritoFinal.findIndex((p) => {
      return p.name === producto.name;
    });
  });

  const carritostorage: any = window.localStorage.getItem('carrito-compra')
  const carroLocal = JSON.parse(carritostorage)
  const finalPrice = carroLocal[0]['price']
  console.log(finalPrice);

  console.log(productosFiltrados, 'filtro aplicado')

  return (
    <Modal show={show} onHide={onHide}>
      {productosFiltrados?.map((item) => {
        return (
          <>
            <Modal.Header closeButton>
              <Modal.Title>{item.name}</Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <Card>
                <Card.Img
                  variant="top"
                  src={item.photo}
                  className="tex-center"
                  style={{ height: "10rem" }}
                />
                <Card.Body>
                  <Card.Text>Marca: {item.marca}</Card.Text>
                </Card.Body>
                <Card.Body>
                  <Card.Text>
                    Precio: {item.price ? `$${item.price}` : "$50.000"}
                  </Card.Text>
                </Card.Body>
                <Card.Body>
                  <Button variant="outline-primary" >
                    -
                  </Button>
                  <span className="mx-2">{item.cantidad}</span>
                  <Button variant="outline-primary" onClick={() => onIncrement(item)} >
                    +
                  </Button>
                </Card.Body>
              </Card>
            </Modal.Body>
          </>
        );
      })}
        <Button variant="outline-primary" 
            style={{ 
            margin: '20px', 
            marginRight: '100px', 
            marginLeft: '100px' }} >
              Comprar
          </Button>
        <Card.Body>
        <Card.Text>
          TOTAL: {finalPrice}
          </Card.Text>
        </Card.Body>
    </Modal>
  );
};