import React from "react";
import { Google } from "react-bootstrap-icons";
import "./Googleregister.css";

export const Googleregister = () => {
  const handleGoogleLogin = () => {
    window.open("http://localhost:3001/auth/google", "_self");
  };
  return (
    <button
      className="google-button btn btn-google"
      onClick={handleGoogleLogin}
    >
      <span className="google-icon"><Google/></span>
      <span className="google-text">Sign in with Google</span>
    </button>
  );
};
