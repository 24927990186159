import { configureStore } from '@reduxjs/toolkit'
import { useSelector } from 'react-redux';
import { TypedUseSelectorHook, useDispatch } from 'react-redux';
import  AppSlice from './slices/slice'; 
import  AuthSlice from './slices/auth-slice';
// ...

export const store = configureStore({
  reducer: {
    agrotienda: AppSlice,
    auth: AuthSlice
  },
});

export const useAppDispatch:()=>typeof store.dispatch=useDispatch
export const useAppSelector:TypedUseSelectorHook<ReturnType<typeof store.getState>>=useSelector;