import React, { useState } from "react";
import Button from "react-bootstrap/Button";
import Card from "react-bootstrap/Card";
import { useAppDispatch, useAppSelector } from "../../redux/store";
import { useEffect } from "react";
import { getAllProducts } from "../../redux/actions";
import { Producto } from "../../interfaces/types";
import Pagination from "react-bootstrap/Pagination";
import { ProductoModal } from "./ProductoModal";
import { Carrito } from "./Carrito";

export const Productos = () => {
  const { filtrosProducto } = useAppSelector(
    (state: { agrotienda: any }) => state.agrotienda
  );
  const dispatch = useAppDispatch();
  useEffect(() => {
    //dispatch(getAllProducts());
  }, []);
  const [showModal, setShowModal] = useState(false);
  const [showCarrito, setShowCarrito] = useState(false);
  const [productoDetail, setProductoDetail] = useState<Producto>();
  const [carritoInfo, setCarritoInfo] = useState<Producto[]>([])
  const handlePageChange = (newPage: number) => {
    setCurrentPage(newPage);
  };

  console.log(filtrosProducto, 'filtrosProductos')

  const [currentPage, setCurrentPage] = useState(1);
  const productsPerPage = 12;
  const indexOfLastProduct = currentPage * productsPerPage;
  const indexOfFirstProduct = indexOfLastProduct - productsPerPage;
  const currentProducts: Producto[] = filtrosProducto?.slice(
    indexOfFirstProduct,
    indexOfLastProduct
  );
  const totalPages = Math.ceil(filtrosProducto?.length / productsPerPage);

  const handleDetail = (producto: Producto) => {
    setProductoDetail(producto)
    setShowModal(true)
  }

  const handleCarrito = (producto: Producto) => {
    setCarritoInfo([
        ...carritoInfo,
        { ...producto }
      ])
    setShowCarrito(true)
  }
  window.localStorage.setItem('carrito-compra', JSON.stringify(carritoInfo))

  return (
    <>
      <h1 className="text-center mt-5 pt-5 pb-3">Productos</h1>
      <div className="d-flex justify-content-center flex-wrap gap-3">
        {currentProducts?.map((producto) => (
          <Card
            style={{
              width: "18rem",
              marginRight: "1.5rem",
              marginLeft: "1.5rem",
            }}
            key={producto._id}
          >
            <Card.Img
              variant="top"
              src={producto.photo}
              style={{ width: "100%", height: "9rem" }}
            />
            <Card.Body>
              <Card.Title>{producto.name}</Card.Title>
              <Card.Text>
                <p>{producto.description?.substring(0, 20)}</p>
              </Card.Text>
              <Card.Text>
                <p>{producto.price ? `$${producto.price}` : "$50.000"}</p>
              </Card.Text>
              <Button onClick={() => handleDetail(producto)}>Ver</Button>
              <Button onClick={() => handleCarrito(producto)}>Añadir al carrito</Button>
            </Card.Body>
          </Card>
        ))}
      </div>
      <div className="d-flex justify-content-center pt-3">
  
      {showModal && <ProductoModal
                producto={productoDetail}
                show={showModal}
                onHide={() => setShowModal(false)}
              /> }

      {showCarrito && <Carrito
                carrito={carritoInfo}
                show={showCarrito}
                onHide={() => setShowCarrito(false)}
              /> }
    
        <Pagination style={{ paddingLeft: "30px", paddingRight: "30px" }}>
          <Pagination.First onClick={() => handlePageChange(1)} />
          <Pagination.Prev
            onClick={() =>
              handlePageChange(currentPage > 2 ? currentPage - 1 : 1)
            }
          />
          {Array.from({ length: totalPages }, (_, i) => {
            return (
              <Pagination.Item
                key={i + 1}
                onClick={() => handlePageChange(i + 1)}
              >
                {i + 1}
              </Pagination.Item>
            );
          })}
          <Pagination.Next
            onClick={() =>
              handlePageChange(currentPage >= totalPages ? 1 : currentPage + 1)
            }
          />
          <Pagination.Last onClick={() => handlePageChange(totalPages)} />
        </Pagination>
      </div>
    </>
  );
};
