import "./NavBar-style-seg-perimetral.css"
import React, { useEffect, useState } from "react";
import { Button } from "react-bootstrap";
import Container from "react-bootstrap/Container";
import Nav from "react-bootstrap/Nav";
import Navbar from "react-bootstrap/Navbar";
import NavDropdown from "react-bootstrap/NavDropdown";
import { useLocation, useNavigate } from "react-router-dom";
import { Link } from "react-router-dom";
import Logo from "../../imagenes/Logo definitivo.png";
import { fetchUserData, logout, setCategoryProductos } from "../../redux/actions";
import { useAppDispatch, useAppSelector } from "../../redux/store";
import { filterProductosCategory } from "../../utils/functions";
import { EnvelopeAt } from "react-bootstrap-icons";

export const NavbarHome = () => {
  const { usuario } = useAppSelector(
    (state: { agrotienda: any }) => state.agrotienda
  );
  const location = useLocation(); // Obtiene la ubicación actual de la página
  const [navbarColor, setNavbarColor] = useState('');
  const { productos } = useAppSelector((state: { agrotienda: any; }) => state.agrotienda)
  const dispatch = useAppDispatch();
  const history = useNavigate();
  useEffect(() => {
    //dispatch(fetchUserData());
    if (location.pathname === "/servicios") {
      setNavbarColor("navbar-segPerimetral");
    } else {
      setNavbarColor("navbar-home");
    }
  }, [location.pathname]);

  const handleLogout = () => {
    console.log("me deslogueo");
    dispatch(logout());
    history("/");
  };
  console.log(productos);
  
  const marcas = productos.map((producto: { marca: any; }) => producto.marca)
  const marcasUnicas: string[] = [];
  for (let i = 0; i < marcas.length; i++) {
    if (marcasUnicas.indexOf(marcas[i]) === -1) {
      marcasUnicas.push(marcas[i]);
    }
  }
  console.log(marcasUnicas); // Output: ['MARCA23', 'donMallas']

  const handleFilterCategory = (e: React.MouseEvent<HTMLButtonElement>) => {
    const result = filterProductosCategory( productos, e.currentTarget.value )
    dispatch(setCategoryProductos(result))
    history('/productos')
  };
  
  return (
    
      <Navbar expand="lg" style={{paddingBottom: "15px"}} className={navbarColor}>
  <Container>
    <Navbar.Brand href="/">
      <img
        src={Logo}
        alt="Icono de la pagina"
        style={{
          width: "120px",
          height: "50px",
          position: "absolute",
          top: "8px",
          left: "1px",
          right: "1px",
          bottom: "5px",
        }}
      />
    </Navbar.Brand>
    <Navbar.Toggle aria-controls="basic-navbar-nav" />
    <Navbar.Collapse id="basic-navbar-nav">
      <Nav className="me-auto">
        <Nav.Link
          style={{
            color: navbarColor === "navbar-home" ? "white" : "#2492EB",
          }}
          href="/servicios"
        >
          <h5>Seguridad Perimetral</h5>
        </Nav.Link>

            {navbarColor === 'navbar-home' ? 
              <Nav.Link style={{ color: navbarColor === 'navbar-home' ? 'white': "#2492EB"}} href="/#contact-us"><h5>Contacto</h5></Nav.Link>
              : 
              <NavDropdown style={{color: 'white'}} className="NavDropDown"
               title="Contacto" id="basic-nav-dropdown">
                <NavDropdown.Item href="#action/3.1">
                  <a href='mailto:infoagrotienda@gmail.com' rel="noreferrer" target='_blank'>
                    <div style={{ margin: '20px' }}>
                      <EnvelopeAt size={60} color='' />
                    </div>
                  </a>
                  <p>Contactanos atraves del correo electrónico</p>
                </NavDropdown.Item>
              </NavDropdown>
               }
      </Nav>

      {/* Colocar el número de teléfono a la derecha */}
        <Nav className="ml-auto">
          <Nav.Link
            style={{
              color: navbarColor === "navbar-home" ? "white" : "#2492EB",
            }}
          >
            <h5>+57 310 248 5870</h5>
          </Nav.Link>
        </Nav>
      </Navbar.Collapse>
    </Container>
  </Navbar>
  );
}

