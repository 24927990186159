import React from 'react'
import { Whatsapp, Facebook, Instagram, Twitter, EnvelopeAt} from 'react-bootstrap-icons';


export const ContactUs = () => {
  return (
    <div>
      <h1 className='text-center mt-10 pb-5 pt-5'>Contactanos</h1>
      <h6 className='text-center mt-3 pb-5' style={{fontSize: '20px', margin: '13px'}}>Siguenos en nuestras redes sociales, a través de Instagram y Facebook, envianos un correo o escribenos por WhatsApp.</h6>
      <div className='d-flex flex-wrap justify-content-center pb-5 mt-3 mb-5' >
        <a href='https://wa.me/573134724853' rel="noreferrer" target='_blank'>
        <div style={{ margin: '20px' }}>
          <Whatsapp size={60} color='green' />
        </div>
        </a>
        <a href='https://www.instagram.com' rel="noreferrer" target='_blank'>
        <div style={{ margin: '20px' }}>
          <Instagram size={60} color='red' />
        </div>
        </a>
        {/* <a href='https://www.twitter.com' rel="noreferrer" target='_blank'>
        <div style={{ margin: '20px' }}>
          <Twitter size={60} color='cyan' />
        </div>
        </a> */}
        <a href='https://www.facebook.com' rel="noreferrer" target='_blank'>
          <div style={{ margin: '20px' }}>
            <Facebook size={60} color='blue' />
          </div>
        </a>
        <a href='mailto:infoagrotienda@gmail.com' rel="noreferrer" target='_blank'>
          <div style={{ margin: '20px' }}>
            <EnvelopeAt size={60} color='#2492EB' />
          </div>
        </a>
      </div>
    </div>
  )
}


