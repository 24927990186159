// import { Grid, Box, CardActionArea, CardActions, CardContent, CardMedia, Typography } from '@mui/material'
import "./AboutService.css";
import Button from 'react-bootstrap/Button';
// import Card from 'react-bootstrap/Card';
import React, { useEffect } from 'react'
import { getAllServices } from '../../redux/actions'
import { useAppDispatch, useAppSelector } from '../../redux/store' 
import { Link } from 'react-router-dom';
import Loading from '../Pages/Loading';
import { Container, Card, ListGroup, NavDropdown, Navbar, Nav } from 'react-bootstrap';
import ImagesServices from './ImagesServices';
import CarouselServices from './CarouselServices';
import { EnvelopeAt, Whatsapp } from 'react-bootstrap-icons';

export const ServiciosHome = () => {
  const { servicios } = useAppSelector((state) => state.agrotienda)
  const dispatch = useAppDispatch()

  //  useEffect(() => {
  //    dispatch(getAllServices())
  //  }, [dispatch])
  //  if (servicios.length === 0) {
  //   return <Loading/>
  // }

  return (
    <>
    <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
    {/* <CarouselServices/> */}
    </div>

    <Container>
      <Card className="mt-5">
        <Card.Header as="h3">Nuestros Servicios</Card.Header>
        <Card.Body>
          <ListGroup variant="flush">
            <ListGroup.Item>
              <strong>1. Instalación de concertina</strong>
            </ListGroup.Item>
            <ListGroup.Item>
              <strong>2. Instalación y mantenimiento de cercas eléctricas y sensorizadas.</strong>
            </ListGroup.Item>
            <ListGroup.Item>
              <strong>3. Instalación y mantenimiento de Alarmas comunitarias y residenciales.</strong>
            </ListGroup.Item>
            <ListGroup.Item>
              <strong>4. Reparación de energizadores.</strong>
            </ListGroup.Item>
            <ListGroup.Item>
              <strong>5. Mantenimiento de rejas.</strong>
            </ListGroup.Item>
            <ListGroup.Item>
              <strong>6. Instalación de sistemas solares.</strong>
            </ListGroup.Item>
            <ListGroup.Item>
              <strong>7. Instalación de lámparas solares.</strong>
            </ListGroup.Item>
            <ListGroup.Item>
              <strong>8. Ofrecemos servicio de asesoría.</strong>
            </ListGroup.Item>
            <ListGroup.Item>
              <strong>9. Contamos con servicio Post-Venta y garantía tanto del producto como de la instalación.</strong>
            </ListGroup.Item>
            <ListGroup.Item>
              <strong>10. Mantenimiento preventivo para conservar la efectividad de las cercas eléctricas.</strong>
            </ListGroup.Item>
          </ListGroup>
        </Card.Body>
      </Card>
      <Card className="mt-4">
        <Card.Body>
          <Card.Text>
            Todos nuestros servicios cuentan con accesorios, complementos y repuestos originales de la más alta calidad,
            optimizando su funcionamiento y duración.
          </Card.Text>
        </Card.Body>
      </Card>

      <div className="d-flex justify-content-center align-items-center" style={{ marginTop: '30px' }}>

  <Button>
  <NavDropdown style={{color: 'white'}} className="NavDropDown"
               title="Cotizaciones" id="basic-nav-dropdown">

                <NavDropdown.Item  href="#action/3.1">
                  <a href='mailto:infoagrotienda@gmail.com' rel="noreferrer" target='_blank'>
                    <div style={{ margin: '20px', marginLeft: '120px', alignItems: "center" }}>
                      <EnvelopeAt size={60} color='' />
                    </div>
                  </a>
                  <p>Contactanos atraves del correo electrónico</p>
                </NavDropdown.Item>

                <NavDropdown.Item href="#action/3.1">
                <a href='https://wa.me/573112975498' rel="noreferrer" target='_blank'>
                  <div style={{ margin: '20px', marginLeft: '120px', alignItems: "center" }}>
                    <Whatsapp size={60} color='green' />
                  </div>
                </a>
                  <p>Contactanos atraves de WhatsApp</p>
                </NavDropdown.Item>

              </NavDropdown>
              </Button>
</div>



      <ImagesServices/>
    </Container>
    </>
  );
}
