import React from 'react'
import { Col, Container, Row } from "react-bootstrap";
import { Usuario } from '../../interfaces/types';
import { useAppSelector } from '../../redux/store';
import defaultImage from '../../imagenes/fotopersonal.png'
import Loading from '../Pages/Loading';

export const UserInfo = () => {
  const { usuario } = useAppSelector((state: { agrotienda: any; }) => state.agrotienda)
  const user: Usuario = usuario.user?.data
  console.log(usuario, 'desde perfil')
  if (usuario.user === null) {
    return <Loading/>
  } else {
    return (
      <div style={{paddingTop: '200px'}}>
        <Container>
          <Row className="align-items-center">
            <Col xs={12} md={4} className="text-center">
              <img
                src={user?.photo ? user.photo : defaultImage}
                alt={`${user?.firstName} ${user?.lastName}`}
                className="img-fluid rounded-circle"
              />
            </Col>
            <Col xs={12} md={8}>
              <h2 className="mb-0">
                {user?.firstName} {user?.lastName}
              </h2>
              <p className="lead">{user?.email}</p>
            </Col>
          </Row>
        </Container>
      </div>
      );
  }
};  
