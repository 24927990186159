import React from "react";
import { FaExclamationTriangle } from "react-icons/fa";

export default function Error404() {
  return (
    <div style={{
        textAlign: 'center', 
        paddingTop: '120px'}} >
      <FaExclamationTriangle size={64} className="text-warning mb-3" />
      <h1>Página no encontrada</h1>
      <p>Lo siento, la página que estás buscando no existe.</p>
    </div>
  );
}
