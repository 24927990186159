import React from 'react'
import CarouselServices from '../Home/CarouselServices'
import { ServiciosHome } from '../Home/ServiciosHome'

export default function SegPerimetral() {
  return (
    <>
    <CarouselServices/>
    <ServiciosHome/>
    <footer className="bg-dark text-white py-3">
          <div className="container pt-5 pb-3">
            <div className="row">
              <div className="col-md-3">
                <h5>Contacto</h5>
                <ul className="list-unstyled">
                  <li>Telefono: +57 3134724853</li>
                  <li>Email: info@agrotienda.com.co</li>
                  <li>Dirección: Calle 134a #53-82</li>
                </ul>
              </div>
              <div className="col-md-3"></div>
              <div className="col-md-3">
                <h5>Links</h5>
                <ul className="list-unstyled">
                  <li>
                    <a
                      href="/#home"
                      style={{ color: "white", textDecoration: "none" }}
                    >
                      Home
                    </a>
                  </li>
                  <li>
                    {/* <a
                      href="/#about-us"
                      style={{ color: "white", textDecoration: "none" }}
                    >
                      Nosotros
                    </a> */}
                  </li>
                  <li>
                  </li>
                  <li>
                    <a
                      href="/#servicios"
                      style={{ color: "white", textDecoration: "none" }}
                    >
                      Servicios
                    </a>
                  </li>
                  <li>
                    <a
                      href="/#contact-us"
                      style={{ color: "white", textDecoration: "none" }}
                    >
                      Contacto
                    </a>
                  </li>
                </ul>
              </div>
              {/* <div className="col-md-3">
                <h5>Newsletter</h5>
                <p>Sign up for updates and promotions</p>
                <form>
                  <div className="form-group">
                    <input
                      type="email"
                      className="form-control"
                      placeholder="Enter email"
                    />
                  </div>
                  <button className="btn btn-light mt-3">Suscribirse</button>
                </form>
              </div> */}
            </div>
            <div className="row">
              <div className="col text-center pt-5">
                <p>Copyright &copy; {new Date().getFullYear()} Agrotienda</p>
              </div>
            </div>
          </div>
        </footer>
    </>
  )
}
