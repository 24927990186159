import React from 'react';
import Carousel from 'react-bootstrap/Carousel';
import ImagenAgrotienda from '../../imagenes/Agrotienda Reja 2.jpg'
import ImagenProductos from '../../imagenes/Agrotienda Reja 3.jpg'
import ImagenServicios from '../../imagenes/Agrotienda Reja 5.jpg'
import ImagenReja from '../../imagenes/Agricultor.jpg'
import './homeStyles.css'

export const CarouselService = () => {

  return (
    <div className='d-flex justify-content-center flex-wrap gap-5 pl-5 align-items-center pt5'>
    <Carousel slide={false} style={{ width: '75%', height: '5%', paddingTop: '15px'}}>
      <Carousel.Item>
        <div>
        <h3 className='text-center'>Productos de la más alta calidad</h3>
          <p className='text-center mt-2'>Solo trabajamos con los mejores insumos.</p>
        </div>
        <img
          className="d-flex w-100 fluid rounded shadow-lg"
          src={ImagenAgrotienda}
          alt="First slide"
          style={{ width: '40%', height: '10%'}}
        />
      </Carousel.Item>
      <Carousel.Item>
      <h3 className='text-center'>Cobertura en todo el país</h3>
          <p className='text-center mt-2'>Cotiza ya nuestros servicios por correo o WhatsApp.</p>
        <img
          className="d-block w-100 fluid rounded shadow-lg"
          src={ImagenProductos}
          alt="Second slide"
          style={{ width: '40%', height: '10%' }}
        />
      </Carousel.Item>
      <Carousel.Item>
      <h3 className='text-center'>Servicio personalizado</h3>
          <p className='text-center mt-2'> La mejor solución a sus necesidades en seguridad.</p>
        <img
          className="d-block w-100 fluid rounded shadow-lg"
          src={ImagenServicios}
          alt="Third slide"
          style={{ width: '60%', height: '20%' }}
        />
        {/* <Carousel.Caption>
          <h3>Servicios para su negocio</h3>
          <p>
            Prestamos los mejores servicios para su negocio.
          </p>
        </Carousel.Caption> */}
      </Carousel.Item>
    </Carousel>
    </div>
  )
}
