import Home from "./components/Home/Home";
import { BrowserRouter, Navigate, Route, Routes } from "react-router-dom";
import './App.css';
import React from "react";
import { Register } from "./components/Auth/register";
import { Productos } from "./components/Pages/Productos";
import { Servicios } from "./components/Pages/Servicios";
import { Login } from "./components/Auth/loggin";
import { NavbarHome } from "./components/Home/Navbar";
import { useAppSelector } from "./redux/store";
import { UserInfo } from "./components/PanelUser/UserInfo";
import { Panel } from "./components/PanelAdmin/Panel";
import Error404 from "./components/Pages/Error404";
import SegPerimetral from "./components/Pages/SegPerimetral";
import PoliticaPrivacidad from "./components/Home/PoliticaPrivacidad";

function App() {
  const {usuario } = useAppSelector((state: { agrotienda: any; }) => state.agrotienda)
  console.log(usuario.user, 'desde app')
  return (
      <BrowserRouter>
      <NavbarHome/>
      <Routes>
        <Route path='/' element={<Home/>}/>
        <Route path='*' element={<Error404/>}/>
        <Route path='/privacy' element={<PoliticaPrivacidad/>}/>
        <Route path='/productos' element={<Productos/>}/>
        <Route path='/servicios' element={<SegPerimetral/>}/>
        <Route path='/register' element={<Register/>}/>
        <Route path='/login' element={<Login/>}/>
        <Route path="/profile" element={<UserInfo />} /> 
        <Route path="/paneldecontrol" element={ usuario.user !== null && usuario?.user?.data?.role === 'Administrador' ? <Panel/> : <Navigate to="/" /> } />
      </Routes>
      </BrowserRouter>
  );
}

export default App;
