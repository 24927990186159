import React from 'react'
import Col from 'react-bootstrap/Col';
import Container from 'react-bootstrap/Container';
import Image from 'react-bootstrap/Image';
import Row from 'react-bootstrap/Row';
import Image1 from '../../imagenes/Agrotienda Reja 2.jpg'
import Image2 from '../../imagenes/Agrotienda Reja 3.jpg'
import Image3 from '../../imagenes/Agrotienda Reja 5.jpg'

export default function ImagesServices() {
  return (
  <Container style={{ marginTop: "20px", paddingLeft: '1px', paddingRight: '3px', marginBottom: '60px' }}>
    <Row>
      <Col xs={8} md={4}>
        <Image style={{width: "350px", height: "200px", paddingRight: '10px', marginTop: "20px" }} src={Image1} rounded />
      </Col>
      <Col xs={8} md={4}>
        <Image style={{width: "350px", height: "200px", paddingRight: '10px', marginTop: "20px" }} src={Image2} rounded />
      </Col>
      <Col xs={8} md={4}>
        <Image style={{width: "350px", height: "200px", paddingRight: '10px', marginTop: "20px" }} src={Image3} rounded />
      </Col>
    </Row>
  </Container>
  )
}
