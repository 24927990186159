import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { AppState, Producto, Servicio, Usuario } from "../../interfaces/types";

const initialState: AppState = {
    productos: [],
    producto: {},
    servicios: [],
    servicio: {},
    usuarios: [],
    usuario: [],
    filtrosProducto: [],
    filtrosServicios: [],
    usuariosFilter: [],
    isLoading: false,
};

const AppSlice = createSlice({
    name: 'agrotienda',
    initialState,
    reducers: {

        // IsLoading --> Para saber si esta cargando o ya termino de cargar
        setIsLoading: (state, action:PayloadAction<boolean>) => {
            state.isLoading = action.payload;
        },
        setUser : (state, action:PayloadAction<any>) => {
            window.localStorage.setItem('user', JSON.stringify(action.payload))
            const usuarioJson: any = window.localStorage.getItem('user')
            const usuarioTS = JSON.parse(usuarioJson)
            state.usuario = usuarioTS
            console.log(state.usuario, 'state.usuario')
        },
        // Productos
        getProducts: (state, action:PayloadAction<Producto[]>) => {
            state.filtrosProducto = action.payload;
            state.productos = action.payload
        },
        getProductById: (state, action:PayloadAction<Producto>) => {
            state.producto = action.payload;
          },
        getProductByName: (state, action:PayloadAction<Producto>) => {
            state.producto = action.payload
        },
        setProductsByCategory: (state, action:PayloadAction<any>) => {
            state.filtrosProducto = action.payload
        },
        // Servicios
        getServicios: (state, action:PayloadAction<Servicio[]>) => {
            state.filtrosServicios = action.payload
            state.servicios = action.payload
        },
        getServicioById: (state, action:PayloadAction<Servicio>) => {
            state.servicio = action.payload
        },
        getServicioByName: (state, action:PayloadAction<Servicio>) => {
            state.servicio = action.payload
        },

        // Usuarios
        getUsers: (state, action:PayloadAction<Usuario[]>) => {
            state.usuariosFilter = action.payload
            state.usuarios = action.payload
        },
        getUserById: (state, action:PayloadAction<Usuario>) => {
            state.usuario = action.payload;
        },
        loginUser: (state, action:PayloadAction<Usuario>)=> {
            state.usuario = action.payload
        }
    }
})

export const {
    getProducts,
    getProductById,
    getProductByName,
    getServicios,
    getServicioById,
    getServicioByName,
    getUsers,
    getUserById,
    loginUser,
    setIsLoading,
    setUser,
    setProductsByCategory
} = AppSlice.actions

export default AppSlice.reducer
