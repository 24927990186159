import { Formik, Form, Field, ErrorMessage } from "formik";
import React, {useEffect} from "react";
import { Button } from "react-bootstrap";
import { useAppDispatch, useAppSelector } from "../../redux/store";
import { manualRegister, getAllUsers } from "../../redux/actions";
import { useNavigate } from "react-router-dom";
import Swal from "sweetalert2";


export const Register = () => {
  const { usuarios } = useAppSelector((state: { agrotienda: any; }) => state.agrotienda)
  const { usuario } = useAppSelector((state: { agrotienda: any; }) => state.agrotienda)
  const history = useNavigate();
  const dispatch = useAppDispatch();
  useEffect(() => {
    dispatch(getAllUsers())
  }, [dispatch]);
  
  if (usuario.user) {
    Swal.fire({
      icon: "warning",
      title: "YA ESTAS REGISTRADO",
      text: "Redireccionando...",
    });
    history("/");
  }

interface FormValues {
  firstName: string;
  lastName: string;
  email: string;
  photo: string;
  password: string;
}

const initialValues = {
  firstName: "",
  lastName: "",
  email: "",
  photo: "",
  password: "",
};

const validate = (values: FormValues) => {
  const errors: FormValues = {} as FormValues;
  if (!values.firstName) {
    errors.firstName = "Required";
  }
  if (!values.lastName) {
    errors.lastName = "Required";
  }
  if (!values.email) {
    errors.email = "Required";
  } else if (
    !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i.test(values.email)
  ) {
    errors.email = "Invalid email address";
  }
  const userEmail = usuarios.filter((user: any) => user.email === values.email)
  if (userEmail.length) {
    errors.email = 'El email ya esta registrado, por favor logueate'
  }
  if (!values.photo) {
    errors.photo = "Required";
  }
  if (!values.password) {
    errors.password = "Required";
  } else if (
    values.password.length < 4 || values.password.length > 12
  ) {
    errors.password = "La contraseña debe tener entre 4 y 12 caracteres";
  }
  return errors;
};

  const onSubmit = (values: any) => {
    dispatch(manualRegister(values))
    history("/");
  };

  return (
    <div className="d-flex justify-content-center" style={{paddingTop: '110px'}}>
      <div className="container col-lg-6 ">
    <Formik initialValues={initialValues} validate={validate} onSubmit={onSubmit}>
      {(formik) => (
        <Form>
          <div className="form-group">
            <label htmlFor="firstName">First Name</label>
            <Field name="firstName" id="firstName" className="form-control"/>
            <ErrorMessage name="firstName" />
          </div>
          <div className="form-group">
            <label htmlFor="lastName">Last Name</label>
            <Field name="lastName" id="lastName" className="form-control" />
            <ErrorMessage name="lastName" />
          </div>
          <div className="form-group">
            <label htmlFor="email">Email</label>
            <Field name="email" id="email" type="email" className="form-control" />
            <ErrorMessage name="email" />
          </div>
          <div className="form-group">
            <label htmlFor="password">Contraseña</label>
            <Field name="password" id="password" type="password" className="form-control"/>
            <ErrorMessage name="password" />
          </div>
          <div className="form-group">
            <label htmlFor="photo">Foto: </label>
            <Field name="photo" id="photo" type="file" className="form-control"/>
            <ErrorMessage name="photo" />
          </div>
          <Button type="submit" disabled={!formik.isValid} className="btn btn-primary mt-3">
            Submit
          </Button>
        </Form>
      )}
    </Formik>
     </div>
    </div>
  );
};
