import React from 'react'
import { FaSpinner } from "react-icons/fa";

export default function Loading() {
    return (
        <div className="text-center">
          <FaSpinner className="fa-spin" size={82} />
          <p>Cargando...</p>
        </div>
      );
}
