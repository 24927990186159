import React from "react";
import "./AboutService.css";
import imageUrl1 from '../../imagenes/Agrotienda Alarma.jpg';
import imageUrl2 from '../../imagenes/Agrotienda Reja 2.jpg'; // Agrega la URL de tu segunda imagen
import { Button } from "react-bootstrap";

export default function AboutService() {
  return (
    <>
    <div className="about-service-container">
      <h2>Seguridad Perimetral</h2>
      <div className="image-paragraph-container">
        <div className="image-container">
          <img src={imageUrl2} alt="Imagen 1" className="image" />
        </div>
        <div className="text-container">
          <p>
          La Cerca Eléctrica permite detectar al intruso cuando está fuera del perímetro de su propiedad y no cuando ya lo ha traspasado. Este sistema detecta y rechaza al invasor, ya que al tocar el cerco recibe una descarga eléctrica extremadamente desagradable sin ser mortal. 
          </p>
          <br />
          <p>
          Su funcionamiento está basado en la generación de pulsos de alta tensión, proporcionados por un equipo de energizado.
          </p>
          <br />
          {/* Tu contenido de texto */}
        </div>
        {/* <div className="image-container">
          <img src={imageUrl1} alt="Imagen 2" className="image" />
        </div> */}
      </div>
    </div>
    <div className="about-service-container">
      <h2>Sistemas de Alarmas</h2>
      <div className="image-paragraph-container">
        <div className="text-container">
          <p>
          La Cerca Eléctrica permite detectar al intruso cuando está fuera del perímetro de su propiedad y no cuando ya lo ha traspasado. Este sistema detecta y rechaza al invasor, ya que al tocar el cerco recibe una descarga eléctrica extremadamente desagradable sin ser mortal. 
          </p>
          <br />
          <p>
          Su funcionamiento está basado en la generación de pulsos de alta tensión, proporcionados por un equipo de energizado.
          </p>
          <br />
          {/* Tu contenido de texto */}
        </div>
        <div className="image-container">
          <img src={imageUrl1} alt="Imagen 1" className="image" />
        </div>
        {/* <div className="image-container">
          <img src={imageUrl1} alt="Imagen 2" className="image" />
        </div> */}
      </div>
      <Button href="/servicios" variant="secondary" style={{backgroundColor: '#2492EB'}}>Seguridad Perimetral</Button>
    </div>
    </>
  );
}
