import React from 'react'
import { ThemeProvider, createTheme } from '@mui/material/styles';
import { CssBaseline } from '@mui/material'
import  StyledEngineProvider  from '@mui/material/StyledEngineProvider'
import { info } from 'console';
import { light } from '@mui/material/styles/createPalette';

interface Props {
    children: React.ReactNode;
}

const MuiThemeProvider: React.FC<Props> = ({ children }) => {
  const theme = createTheme({
    palette: {
      primary: {
        main: '#88BA03',
        light: '#25612B'
      },
      secondary: {
        main: '#2492EB',
        light: '#00E7FF'
      }
    }
  });

  return (
    <StyledEngineProvider>
      <ThemeProvider theme={theme}>
        <CssBaseline/>
          {children}
        </ThemeProvider>
      </StyledEngineProvider>
  )
}

export default MuiThemeProvider
