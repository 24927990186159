import React from "react";
import { Facebook } from "react-bootstrap-icons";
import "./Facebookregister.css";

export const FacebookRegister = () => {
  const handleFacebookLogin = () => {
    window.open("http://localhost:3001/auth/facebook", "_self");
  };
  return (
    <button
      className="facebook-button btn btn-facebook"
      onClick={handleFacebookLogin}
    >
      <span className="facebook-icon"><Facebook/></span>
      <span className="facebook-text">Sign in with Facebook</span>
    </button>
  );
};